import React, { useState, useContext, useEffect } from 'react'
import styles from './NewLayout.module.css'
import { Button, Modal, notification, Tooltip } from 'antd'
import { AiOutlineClose, AiOutlineLeft, AiOutlineSearch } from 'react-icons/ai'
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs'
import { MdFilterList } from 'react-icons/md'
import CloudArrowDown from '../../../../src/Assets/CloudArrowDown.svg'
import UploadSimple from '../../../../src/Assets/UploadSimple.svg'
import { useSelector } from 'react-redux'
import House from './NewLayoutAssets/House.svg'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { Dropdown, OverlayTrigger } from 'react-bootstrap'
import { Dropdown as aDropdown } from 'antd'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TbGridDots } from 'react-icons/tb'
import Filter from './Filter'
import executionquo from '../../Lms/images/executionquo.svg'
import designquo from '../../Lms/images/designquo.svg'
import { useDispatch } from 'react-redux'
import LmsMail from '../../Lms/LmsMail.js/LmsMail'
import {
  setSidebarToggle,
  isFilterOpen,
  setShowEmailModal,
  setSelectedTabs,
  setShowModalPipe,
} from '../../../Redux/Slices/lmsSlice'
import AddLeadModalNew from '../../Lms/AddLeadModalNew'
import {
  updateCallStatusUpdatePanel,
  updatePanelOpen,
} from '../../../Redux/Slices/specificleadSlice'
import { createProjectModal } from '../../../Redux/Slices/lmsSlice'
import {
  setAddLeadModalOpen,
  setHeaderTab,
  setShowImport,
} from '../../../Redux/Slices/newLeadSlice'
import CreateNewProjectNew from '../../Lms/CreateNewProject/CreateNewProjectNew'
import desquoContext from '../../../picontext/DashBoard/desquoContext'
import { getReq, postReq } from '../../../piservices/apis'
import expf, { constants } from '../../../piservices/constants'
import { setSearchTerms } from '../../../Redux/Slices/lmsSlice'
import SendMail from '../../Lms/LeadUpdate/SendMail'
import axios from 'axios'
import { projectDataCurrObj, setIsChecked } from '../../../Redux/Slices/convertToProjectSlice'
import LockBlack from '../../../Assets/LockBlack.svg'
import LockWhite from '../../../Assets/LockWhite.svg'
import useApiFetchCustomHook from '../../../piservices/ApiFetchCustomHook'
import AddLeadModalNewForFormTemplate from '../../Lms/AddLeadModalNewForFormTemplate'
import useApiFetchForLeadCountCustomHook from '../../../piservices/ApiFetchForLeadCount'
import { CSVLink } from 'react-csv'

import settingsIcon from '../../../Assets/Gear.svg'
import AddLead from './Modals/AddLead'
import toast from 'react-hot-toast'
import ImportFromCSVModal from '../../Lms/CSVHandlerFolder/ImportFromCSVModal'

// import { setSidebarToggle } from '../Redux/slices/filemanagerSlice'
// let selectedTab = ''
// export function selectedHeaderTab(t) {
//   selectedTab = t
// }
const ProjectHeader = ({
  handleTrue,
  toggler,
  leadsDetail,
  setUpdatePanel,
  // a,
  // searchTerms,
  // setSearchTerms,
}) => {
  let { tab } = useParams()
  const a = useSelector((state) => state.lmsSlice.selectedTab)
  const dispatch = useDispatch()
  const selectedLeadsData = useSelector((state) => state.selectedLeadSlice.selectedLeadData)
  const headerTab = useSelector((state) => state.newLeadSlice.headerTab)
  useEffect(() => {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      dispatch(setHeaderTab('Leads Management'))
    }
  }, [dispatch])
  const showEmailModal = useSelector((state) => state.lmsSlice.showEmailModal)
  const addLeadFormTemplate = useSelector((state) => state.lmsSlice.addLeadFormTemplate)
  console.log('addLeadFormTemplate', addLeadFormTemplate)

  // const history = useHistory();
  const userData = useSelector((state) => state.lmsSlice.userData)

  console.log('Userdata', userData)
  const context = useContext(desquoContext)
  const [searchL, setSearchL] = useState('')
  const { leads, setLeads, setMailStatus, activeLead, getActive } = context
  const [quotationModal, setQuotationModal] = useState(false)
  const [currentQuot, setCurrentQuot] = useState('')
  const [search, setSearch] = useState(false)
  const [quotInput, setQuotInput] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [filter, setFilter] = useState(false)
  const [toggleState, setToggleState] = useState(true)
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false)
  //  getting user data
  const [AuthUserData, setAuthUserData] = useState({})
  const [quotationModal2, setQuotationModal2] = useState(false)
  const [checkboxData, setCheckBoxData] = useState([])
  //  set user data

  const addLeadModalOpen = useSelector((state) => state.newLeadSlice.addLeadModalOpen)
  console.log(addLeadModalOpen, 'qecdwf')
  const [show, setShow] = useState(false)

  const isFilterOpenRedux = useSelector((state) => state.lmsSlice.isFilterOpen)
  const { apiFetch } = useApiFetchCustomHook()

  const { getLeadsCount, getLeadFormTemplate } = useApiFetchForLeadCountCustomHook()

  const [searchParams] = useSearchParams()
  const openAddLeadModalFromTopCommonHeader = searchParams.get('add-leads')
  useEffect(() => {
    setCheckBoxData(leads?.data)
    if (openAddLeadModalFromTopCommonHeader) {
      dispatch(setAddLeadModalOpen(true))
    }
    // setMailStatus();
  }, [])
  const goBack = () => navigate(-1)
  const goForward = () => navigate(1)
  const searchTerms = useSelector((state) => state.lmsSlice.searchTerms)

  //  get user data

  useEffect(() => {
    getUserbyId(localStorage.getItem('userId'))
  }, [])

  // open add lead modal
  const showAddLead = () => {
    setShow(true)
  }

  // hide add lead modal
  const hideAddLead = () => {
    setShow(false)
  }

  // add new lead
  const addLeadHandler = () => {
    showAddLead()
  }
  const [editLeadBoolean, setEditLeadBoolean] = useState(false)

  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem('userId')}`
    )

    if (res && !res.error) {
      setEditLeadBoolean(res?.data?.data?.rolesAssigned?.crm?.edit)
      setAuthUserData(res?.data?.data)
    } else {
      console.log(res.error)
    }
  }

  console.log('userData', AuthUserData?.rolesAssigned?.CRM_API)
  const init = async () => {
    //  await setProgress(30);
    const res = await getReq(constants.CRM_API + '/user/newLeadsData?pageNo=0')
    if (res && !res.error) {
      setCheckBoxData(res?.data?.data?.data)
      let arr = res?.data?.data?.data?.filter((item) => {
        if (item?.name?.toLowerCase()?.includes(searchL?.toLowerCase())) {
          return true
        }
      })
      let x = JSON.parse(JSON.stringify(res?.data?.data))
      delete x['data']
      x['data'] = arr
      setLeads(x)
    }
  }

  const handleQuotOk = () => {
    setQuotationModal(true)
  }
  const handleQuotChange = (e) => {
    setQuotInput(e.target.value)
  }
  const handleCloseModal = () => {
    setQuotationModal(false)
  }
  const handleCloseModal2 = () => {
    setQuotationModal2(false)
  }
  const handleOk2 = () => {
    setQuotationModal2(true)
  }
  const handleSearch = () => {
    if (searchTerms?.length === 0) {
      setSearch(false)
    }
  }
  const getUserName = async (projectId) => {
    try {
      const res = await getReq(
        `${constants.PMT_API}/api/projects/get-project-byId?projectId=${projectId}`
      )
      return res?.data?.createdByName || null
    } catch (error) {
      console.error(`Error fetching data for projectId ${projectId}:`, error)
      return null
    }
  }

  const [createdByNameArray, setCreatedByNameArray] = useState([])
  console.log(leads, 'xqwxwqxa')
  // useEffect(() => {
  //   const fetchNames = async () => {
  //     if (!leads || !leads.data) return; // Exit if leads data is not available

  //     const namesArray = [];
  //     for (const lead of leads.data) {
  //       const { projectId } = lead;

  //       // Only make API call if projectId is defined
  //       if (projectId) {
  //         try {
  //           const createdByName = await getUserName(projectId);
  //           namesArray.push(createdByName);
  //           lead.createdByName = createdByName;
  //         } catch (error) {
  //           console.error(`Error fetching data for projectId ${projectId}:`, error);
  //         }
  //       }
  //     }
  //     setCreatedByNameArray(namesArray);
  //   };

  //   fetchNames();
  // }, [leads]); // Depend on leads

  const csvHeader = [
    'First Name',
    'Last Name',
    'Email',
    'Phone Number',
    'Lead status',
    'Expected Closure',
    'Budget',
    'Scope',
    'Address',
    'City',
    'Area',
    'Pin Code',
    'Lead Source',
    'Source Name',
    'Assigned To',
    'Last Call Date',
    'Convert to Project on Date',
    'Follow up Date and Time',
    // "Converted By ",
    'Last Call Status',
  ]
  console.log('YeRahaData2', leads?.data)
  let csvData = []

  if (leads) {
    csvData = leads?.data.map(
      (
        {
          name,
          firstName,
          lastName,
          email,
          phoneNumber,
          leadStatus,
          clouserMonth,
          budget,
          requirements,
          address,
          city,
          area,
          pinCode,
          leadSource,
          sourceName,
          assignedTo,
          leadUpdates,
          createdByName,
        },
        i
      ) => [
        firstName || name,
        lastName || '',
        email,
        phoneNumber,
        leadStatus !== 1
          ? leadStatus === 2
            ? 'Follow Up'
            : leadStatus === 3
              ? 'Interested'
              : leadStatus === 5
                ? 'Not Interested'
                : leadStatus === 6
                  ? 'No Response'
                  : ''
          : 'New',
        clouserMonth || '',
        budget,
        requirements,
        address,
        city,
        area,
        pinCode,
        leadSource,
        sourceName,
        assignedTo?.fullName,
        leadUpdates[leadUpdates?.length - 1]?.updateDate,
        leadUpdates[leadUpdates?.length - 1]?.updateDate,
        leadUpdates[leadUpdates?.length - 1]?.updateDate === undefined
          ? ''
          : leadUpdates[leadUpdates?.length - 1]?.updateDate +
            ', ' +
            leadUpdates[leadUpdates?.length - 1]?.updateTime,
        // createdByNameArray[i],
        leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 1
          ? 'New'
          : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 2
            ? 'Follow Up'
            : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 3
              ? 'Interested'
              : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 5
                ? 'Not Interested'
                : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 6
                  ? 'No Response'
                  : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 7
                    ? 'Meeting'
                    : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 4
                      ? 'Sign Up'
                      : '',
      ]
    )
  }
  const handleOk22 = async () => {
    if (quotInput != '') {
      const res2 = await postReq(`${constants.CRM_API}/user/edit-lead?id=${activeLead?._id}`, {
        // leadId: activeLead?._id,
        email: quotInput,
      })
      if (res2 && !res2.error) {
        const res = await postReq(
          `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem('userId')}`,
          {
            email: quotInput,
            location: activeLead.address,
            name: activeLead.name,
            projectName: activeLead.name,
          }
        )
        if (res && !res.error) {
          console.log(res)
        } else {
          alert(res?.response?.data?.message)
          console.error(res.error)
        }
        await axios
          .post(`${constants.PMT_API}/api/projects/add-project`, {
            clientId: res.data._id,
            location: activeLead.address,
            projectName: activeLead.name,
            userId: localStorage.getItem('userId'),
            isConfirmed: false,
            isFromLead: true,
            projectAdmin: activeLead?.assignedTo?._id,
            teamMembers: [activeLead?.assignedTo?._id],
          })
          .then(function (response) {
            localStorage.setItem('projectId', response?.data?._id)
            if (currentQuot == 'exQuot') {
              localStorage.setItem('projectId', response?.data?._id)
              setQuotInput('')
              setCurrentQuot('')
              window.location.assign(
                `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`
              )
            } else {
              localStorage.setItem('projectId', response?.data?._id)
              setQuotInput('')
              setCurrentQuot('')
              window.location.assign(
                `${constants.BASE_URL}/quotation/enterprise-quotation/${response.data._id}`
              )
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      } else {
        console.log(res2.error)
      }
    }
  }
  const createProjectAndGoToDesignQuotation = async () => {
    if (activeLead?.projectId) {
      localStorage.setItem('projectId', activeLead?.projectId)
      window.location.assign(
        `${constants.BASE_URL}/quotation/enterprise-quotation/${activeLead?.projectId}`
      )
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem(
                'userId'
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${constants.PMT_API}/api/projects/add-project?userId=${localStorage.getItem(
                    'userId'
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem('userId'),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  localStorage.setItem('projectId', response?.data?._id)
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab)
                      window.location.assign(
                        `${constants.BASE_URL}/quotation/enterprise-quotation/${response?.data?._id}`
                      )
                    })
                    .catch((err) => {
                      console.log(err.error)
                      notification.error({
                        message: err?.response?.data?.message || 'client already exist',
                        duration: 1,
                      })
                    })
                })
                .catch((e) => {
                  console.log(e)
                  notification.error({
                    message: e?.response?.data?.message || 'project already exists',
                    duration: 1,
                  })
                })
                .catch((err) => {
                  notification.error({
                    message: err?.response?.data?.message || 'client already exists',
                    duration: 1,
                  })
                })
            })
        } catch (e) {
          console.log(e)
          notification.error({
            message: e?.response?.data?.message || 'client already exists',
            duration: 1,
          })
        }
      } else {
        setCurrentQuot('desQuot')
        handleCloseModal()
        handleOk2()
      }
    }
  }
  const createProjectAndGoToQuotation = async () => {
    if (activeLead?.projectId) {
      localStorage.setItem('projectId', activeLead?.projectId)
      if (window.location.href.includes('erp.essentiaenvironments.com')) {
        window.open(
          `${constants.BASE_URL}/quotation/${activeLead?.projectId}?isLead=true`,
          '_blank'
        )
      } else {
        window.open(
          `${constants.BASE_URL}/quotation/new-quotation/${activeLead?.projectId}?isLead=true`,
          '_blank'
        )
      }
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${constants.PMT_API}/api/projects/addClient?userId=${localStorage.getItem(
                'userId'
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${constants.PMT_API}/api/projects/add-project?userId=${localStorage.getItem(
                    'userId'
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem('userId'),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  localStorage.setItem('projectId', response?.data?._id)
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab)
                      window.open(
                        `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`,
                        '_blank'
                      )
                    })
                    .catch((err) => {
                      console.log(err.error)
                      notification.error({
                        message: err?.error?.response?.data?.message || 'client already exist',
                        duration: 1,
                      })
                    })
                })
                .catch((e) => {
                  console.log(e)
                  notification.error({
                    message: e?.error?.response?.data?.message || 'project already exists',
                    duration: 1,
                  })
                })
                .catch((err) => {
                  notification.error({
                    message: err?.error?.response?.data?.message || 'client already exists',
                    duration: 1,
                  })
                })
            })
        } catch (e) {
          console.log(e)
          notification.error({
            message: e?.response?.data?.message || 'client already exists',
            duration: 1,
          })
        }
      } else {
        setCurrentQuot('desQuot')
        handleCloseModal()
        handleOk2()
      }
    }
  }

  useEffect(() => {
    dispatch(isFilterOpen(false))
    if (a == 'Analytics') {
      dispatch(isFilterOpen(false))
      setFilter(false)
    }
  }, [a])

  // useEffect(() => {
  //   if (
  //     sArea?.length > 0 ||
  //     sBudget?.length > 0 ||
  //     sScope?.length > 0 ||
  //     sExpClosue?.length > 0 ||
  //     sAssignedTo?.length > 0
  //   ) {
  //     const filterData = checkboxData.filter((item) => {
  //       return (
  //         sArea?.includes(item.address) ||
  //         sBudget?.includes(item.budget) ||
  //         sScope?.includes(item.requirements) ||
  //         sAssignedTo?.includes(item?.assignedTo?.fullName) ||
  //         sExpClosue?.includes(item.clouserMonth)
  //       );
  //     });
  //     let obj = {};

  //     let x = JSON.parse(JSON.stringify(leads));
  //     delete x["data"];
  //     x["data"] = filterData;
  //     setLeads(x);

  //     // x.data = obj
  //     //     setLeads(x)
  //   }
  // }, [sArea, sScope, sBudget, sExpClosue, sAssignedTo]);
  let count = 0
  function isObjectBlank(obj) {
    return Object.keys(obj).length === 0
  }

  const profileData = useSelector((state) => state.persist.profileDataDataValue)

  const openSettingsHandler = () => {
    navigate('/leads/lead-management-settings')
  }
  const showImport = useSelector((state) => state.newLeadSlice.showImport)

  return (
    <>
      {addLeadFormTemplate && Object.keys(addLeadFormTemplate).length > 0 ? (
        <AddLeadModalNewForFormTemplate />
      ) : (
        <AddLeadModalNew />
      )}
      <ImportFromCSVModal showImport={showImport} />
      <Modal
        // title={false}
        open={quotationModal}
        onOk={handleQuotOk}
        onCancel={handleCloseModal}
        centered
        footer={null}
        // closable={false}
        title="Select the quotation"
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <div
            onClick={createProjectAndGoToDesignQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5"
            style={{
              background: '#FFFFFF',
              border: '1px solid #DFDFDF',
              borderRadius: '8px',
              width: '50%',
              cursor: 'pointer',
            }}
          >
            <img src={designquo} style={{ height: '2.75rem', width: '2.75rem' }} />
            <div
              className="mt-4"
              style={{
                color: '#444444',
                fontSize: '15px',
                fontWeight: '600',
              }}
            >
              Design Quotation
            </div>
          </div>
          <div
            onClick={createProjectAndGoToQuotation}
            className="d-flex flex-column align-items-center justify-content-center  py-5 ms-4"
            style={{
              background: '#FFFFFF',
              border: '1px solid #DFDFDF',
              borderRadius: '8px',
              width: '50%',
              cursor: 'pointer',
            }}
          >
            <img src={executionquo} style={{ height: '2.75rem', width: '2.75rem' }} />
            <div
              className="mt-4"
              style={{
                color: '#444444',
                fontSize: '15px',
                fontWeight: '600',
              }}
            >
              Execution Quotation
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        // title={false}
        open={quotationModal2}
        onOk={handleOk2}
        onCancel={handleCloseModal2}
        centered
        title={currentQuot === 'exQuot' ? `Send Execution Quotation` : `Send Design Quotation`}
        footer={
          <div>
            <Button onClick={handleCloseModal2}>Cancel</Button>
            <Button
              style={{
                background: 'rgb(23,78,134)',
                color: 'white',
                borderRadius: '0.25rem',
                fontSize: '13px',
              }}
              onClick={handleOk22}
            >
              Send
            </Button>
          </div>
        }
        closable={false}
        // className="ant-modal-centered"
      >
        <div className="d-flex mt-2">
          <input
            placeholder="Enter Lead Email"
            name="quotInput"
            value={quotInput}
            onChange={handleQuotChange}
            className="quoEmail"
          />
        </div>
      </Modal>
      <AddLead show={show} hide={hideAddLead} />
      <div className="d-flex flex-column">
        <div>
          <div
            className={styles.mainContainer2}
            style={{
              opacity: addLeadModalOpen ? '-1' : '1',
            }}
          >
            <div className="d-flex align-items-center h-100" style={{ marginLeft: '-0.2%' }}>
              {!toggler && (
                <div className="d-flex align-items-center h-100" style={{ width: '2.5rem' }}>
                  <TbGridDots
                    size={22}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleTrue()
                      setToggleState(!toggleState)
                      count += 1
                      if (count > 1) {
                      }
                      if (toggleState === true) {
                        setTimeout(() => {
                          dispatch(setSidebarToggle(!toggleState))
                        }, 200)
                      } else {
                        dispatch(setSidebarToggle(!toggleState))
                        // document.getElementById('sidenav_itemsmainstill').setAttribute('id', 'sidenavitemsmain_sub')
                      }

                      if (toggleState === true) {
                        if (document.getElementById('sidenav__items')) {
                          document.getElementById('sidenav__items').style.display = 'none'
                        }
                        if (document.getElementById('sidenav_items_main_sub')) {
                          document
                            .getElementById('sidenav_items_main_sub')
                            .setAttribute('id', 'sidenav_items_main')
                        }
                      } else if (toggleState === false) {
                        if (document.getElementById('sidenav_items_main')) {
                          document
                            .getElementById('sidenav_items_main')
                            .setAttribute('id', 'sidenav_items_main_sub')
                          document.getElementById('sidenav_items_main_sub').style.animation =
                            'anim1 0.5s forwards'
                        }

                        if (document.getElementById('sidenav__items')) {
                          document.getElementById('sidenav__items').style.display = 'flex'
                        }
                      }
                    }}
                  />
                </div>
              )}
              {/* <div className={styles.routing__btns}>
                <BsChevronLeft
                  width={24}
                  height={27}
                  onClick={goBack}
                  style={{ cursor: "pointer", color: "black" }}
                />
                <BsChevronRight
                  width={24}
                  height={27}
                  // fill='#e3e3e3'
                  onClick={goForward}
                  style={{ cursor: "pointer", color: "black" }}
                />
              </div> */}
              <div className={styles.name__section}>
                <div className="d-flex" style={{ gap: '10px', height: '51px' }}>
                  <div
                    style={{
                      borderBottom: headerTab === 'Leads Management' ? '2px solid #0084EF' : 'none',
                      color: headerTab === 'Leads Management' ? '#0084EF' : '#888686',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '160px',
                      justifyContent: 'center',
                      marginTop: '15px',
                      cursor: 'pointer',
                      fontWeight: headerTab === 'Leads Management' ? 600 : 400,
                    }}
                    onClick={() => {
                      const currentUrl = window.location.href
                      const leadFullDetailsPattern = /\/lead-full-details\/[^/]+/

                      if (leadFullDetailsPattern.test(currentUrl)) {
                        window.location.href = currentUrl.replace(leadFullDetailsPattern, '/')
                      }

                      // setSelectedtab("Leads Management");
                      localStorage.setItem('headerTab', 'Leads Management', 'Pipelines')
                      dispatch(setHeaderTab('Leads Management'))
                      // setSelectedHeaderTab("Leads Management");
                      dispatch(setSelectedTabs('Leads Management'))
                    }}
                  >
                    Lead Management
                  </div>

                  <div
                    style={{
                      borderBottom: headerTab === 'Pipelines' ? '2px solid #0084EF' : 'none',
                      color: headerTab === 'Pipelines' ? '#0084EF' : '#888686',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '125px',
                      justifyContent: 'center',
                      marginTop: '15px',
                      cursor: 'pointer',
                      fontWeight: headerTab === 'Pipelines' ? 600 : 400,
                    }}
                    onClick={() => {
                      const currentUrl = window.location.href
                      const leadsPatternWithId = /\/leads\/\d+/
                      const leadsExactPattern = /\/leads\/$/
                      if (leadsPatternWithId.test(currentUrl)) {
                        window.location.href = '/leads/'
                      } else if (!leadsExactPattern.test(currentUrl)) {
                        window.location.href = '/leads/'
                      }
                      localStorage.setItem('headerTab', 'Pipelines')
                      dispatch(setHeaderTab('Pipelines'))
                      dispatch(isFilterOpen(false))
                      dispatch(setSelectedTabs('Pipelines'))
                    }}
                  >
                    Pipeline
                  </div>
                  <div
                    style={{
                      borderBottom: headerTab === 'Analytics' ? '2px solid #0084EF' : 'none',
                      color: headerTab === 'Analytics' ? '#0084EF' : '#888686',
                      fontSize: '14px',
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '125px',
                      justifyContent: 'center',
                      marginTop: '15px',
                      cursor: 'pointer',
                      fontWeight: headerTab === 'Analytics' ? 600 : 400,
                    }}
                    onClick={() => {
                      const currentUrl = window.location.href
                      const leadsPatternWithId = /\/leads\/\d+/
                      const leadsExactPattern = /\/leads\/$/
                      if (leadsPatternWithId.test(currentUrl)) {
                        window.location.href = '/leads/'
                      } else if (!leadsExactPattern.test(currentUrl)) {
                        window.location.href = '/leads/'
                      }
                      localStorage.setItem('headerTab', 'Analytics')
                      dispatch(setHeaderTab('Analytics'))
                      dispatch(isFilterOpen(false))
                      dispatch(setSelectedTabs('Analytics'))
                    }}
                  >
                    Analytics
                  </div>
                </div>
                {/* <BsChevronRight width={24} fill='#66737F' /> */}
                {/* <div className={styles.project}>File Manager</div> */}
              </div>
            </div>
            <div className="d-flex align-items-center" style={{ gap: '10px' }}>
              {/* {a == "Leads Management" && !leadsDetail && (
                <div
                  className="d-flex align-items-center px-2"
                  style={
                    filter
                      ? {
                        background: "#F3F3F4",
                        borderRadius: "0.5rem",
                        cursor: "pointer",
                        height: "2.185rem",
                      }
                      : { cursor: "pointer", height: "2.185rem" }
                  }
                  onClick={() => {
                    setFilter(!filter);
                    dispatch(isFilterOpen(!isFilterOpenRedux));
                  }}
                >
                  <MdFilterList color={filter ? "#333333" : "#6F6E6E"} />
                  <div
                    className={styles.filter_icon}
                    style={
                      filter
                        ? { color: "#333333", userSelect: "none" }
                        : { userSelect: "none" }
                    }
                  >
                    Filter
                  </div>
                  {filter ? (
                    <BiChevronUp
                      size={16}
                      color={filter ? "#333333" : "#6F6E6E"}
                      style={{ marginTop: "0.05rem" }}
                    />
                  ) : (
                    <BiChevronDown color={filter ? "#333333" : "#6F6E6E"} />
                  )}
                </div>
              )} */}
              {/* {headerTab === "Leads Management" && profileData.isAdmin && ( */}
              {headerTab === 'Leads Management' && (
                <div
                  style={{
                    backgroundColor: '#FAFAFA',
                    padding: '8px 8px',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={openSettingsHandler}
                >
                  <img src={settingsIcon} alt="Settings" />
                </div>
              )}
              {a == 'Leads Management' && !leadsDetail && (
                <div className="d-flex align-items-center">
                  {search === false ? (
                    <div
                      className={styles.search_inactive}
                      onClick={() => {
                        setSearch(true)
                        // document.getElementById("serach-in").focus()
                      }}
                    >
                      <AiOutlineSearch color="#6F6E6E" />
                      <div className={styles.filter_icon} style={{ padding: '0' }}>
                        Search
                      </div>
                    </div>
                  ) : (
                    <div className={styles.search_active}>
                      <div className="d-flex align-items-center" onBlur={handleSearch}>
                        <AiOutlineSearch color="#6F6E6E" />
                        <input
                          className={styles.filter_icon}
                          id="serach-in"
                          onChange={(e) => dispatch(setSearchTerms(e.target.value))}
                          placeholder="Search"
                          style={{
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            color: 'black',
                            height: '100%',
                            background: 'transparent',
                          }}
                          autoFocus="true"
                        />
                      </div>
                      <AiOutlineClose
                        size={22}
                        style={{ cursor: 'pointer' }}
                        color="#6F6E6E"
                        onClick={() => (setSearch(false), dispatch(setSearchTerms('')))}
                      />
                    </div>
                  )}
                </div>
              )}
              {headerTab === 'Leads Management' && (
                <CSVLink filename="lead_data.csv" data={[csvHeader, ...csvData]}>
                  <div
                    style={{
                      cursor: 'pointer',
                      width: '40px',
                      height: '35px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#F8FAFB',
                      borderRadius: '6px',
                    }}
                  >
                    <img src={CloudArrowDown} alt="" />
                  </div>
                </CSVLink>
              )}
              {headerTab === 'Leads Management' && (
                <div
                  onClick={() => {
                    dispatch(setShowImport(true))
                  }}
                  style={{
                    cursor: 'pointer',
                    width: '40px',
                    height: '35px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F8FAFB',
                    borderRadius: '6px',
                  }}
                >
                  <img src={UploadSimple} alt="" />
                </div>
              )}
              {headerTab === 'Pipelines' && (
                <div
                  className="addPipe"
                  style={{ cursor: editLeadBoolean ? 'pointer' : 'default' }}
                  onClick={() => {
                    editLeadBoolean
                      ? dispatch(setShowModalPipe(true))
                      : toast.error('Access disabled by Admin')
                  }}
                >
                  Edit Pipeline
                </div>
              )}
              <div className="d-flex align-items-center">
                {!leadsDetail && (
                  <div
                    className="d-flex align-items-center h-100 pe-0"
                    style={{ marginRight: '-0.3rem', color: '#fff' }}
                  >
                    <button
                      disabled={filter}
                      style={
                        filter
                          ? {
                              borderRadius: '0.5rem',
                              // border: "1px solid #0084EF",
                              color: '#fff',
                              background: '#C3C3C3',
                              height: '2.185rem',
                            }
                          : {
                              borderRadius: '0.5rem',
                              color: '#fff',
                              border: '1px solid #0084EF',
                              background: '#0084EF',
                              height: '2.185rem',
                            }
                      }
                      className="mv-w-100 box-shadow-none border-none"
                      data-mdb-toggle="modal"
                      data-mdb-target="#addLeadModal"
                      onClick={() =>
                        // getLeadFormTemplate(),
                        dispatch(setAddLeadModalOpen(true))
                      }
                      // onClick={addLeadHandler}
                    >
                      Add Lead
                    </button>
                  </div>
                )}
                {/* {leadsDetail && !activeLead?.isProjectCreated && (
                  <div
                    className="d-flex flex-column"
                    style={{ marginRight: "0.75rem" }}
                  >
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{ background: "#fff" }}
                        className="d-flex align-items-center justify-content-between p-1 border-none px-1"
                      >
                        <div
                          className="d-flex align-items-center justify-content-between "
                          style={{
                            border: "1px solid #0084EF",
                            borderRadius: "6px",
                            background: "#FFF",
                            padding: "8px 10px",
                            height: "2.185rem",
                          }}
                        >
                          <div
                            className="mx-2"
                            style={{
                              fontSize: "16px",
                              color: "#0084EF",
                              fontWeight: "400",
                            }}
                          >
                            Actions
                          </div>
                          <BiChevronDown size={25} color="#0084EF" />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{ color: "black" }}
                          onClick={() =>
                            dispatch(updateCallStatusUpdatePanel(true))
                          }
                        >
                          Update
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ color: "black" }}
                          onClick={() => {
                            setMailStatus();
                            dispatch(setShowEmailModal(true));
                          }}
                        >
                          Send Email
                        </Dropdown.Item>
                        {
                          userData?.rolesAssigned?.quotation?.edit ? (
                            <Dropdown.Item
                              style={{ color: "black" }}
                              onClick={createProjectAndGoToQuotation}
                            >
                              Send Quotation
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item>
                              <Tooltip
                                title="Access Disabled by the Admin"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#00000040",
                                }}
                              >
                                Send Quotation{" "}
                                <img src={LockBlack} alt="LockImg"></img>
                              </Tooltip>
                            </Dropdown.Item>
                          )
                        
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )} */}
                {/* {leadsDetail && !activeLead?.isProjectCreated && (
                  <div
                    className="d-flex align-items-center h-100 pe-0"
                    style={{ marginRight: "-0.3rem", color: "#fff" }}
                  >
                    <button
                      style={
                        filter
                          ? {
                              borderRadius: "0.5rem",
                              padding: "0px 16px",
                              color: "#fff",
                              background: "#C3C3C3",
                              height: "2.185rem",
                            }
                          : {
                              borderRadius: "0.5rem",
                              color: "#fff",
                              padding: "0px 16px",
                              border: !isObjectBlank(userData)
                                ? userData?.rolesAssigned?.pmt
                                    ?.canCreateProject || userData?.isSuperAdmin
                                  ? "1px solid #0084EF"
                                  : "1px solid #C3C3C3"
                                : "1px solid #0084EF",
                              background: !isObjectBlank(userData)
                                ? userData?.rolesAssigned?.pmt
                                    ?.canCreateProject || userData?.isSuperAdmin
                                  ? "#0084EF"
                                  : "#C3C3C3"
                                : "#0084EF",
                              height: "2.185rem",
                            }
                      }
                      className=" mv-w-100 box-shadow-none border-none"
                      disabled={
                        !(
                          userData?.rolesAssigned?.pmt?.canCreateProject ||
                          userData?.isSuperAdmin
                        )
                      }
                      onClick={() => {
                        let projectDataObj = {
                          firstName: "",
                          lastName: "",
                          projectName: "",
                          address: "",
                          city: "",
                          code: "",
                          phnNum: "",
                          email: "",
                          document: "",
                          documentLink: "",
                        };
                        dispatch(setIsChecked(true));
                        setCreateProjectModalOpen(true);
                        projectDataObj.firstName = selectedLeadsData?.firstName
                          ? selectedLeadsData?.firstName
                          : selectedLeadsData?.name;
                        projectDataObj.lastName = selectedLeadsData.lastName;
                        projectDataObj.address = selectedLeadsData.address;
                        projectDataObj.phnNum = selectedLeadsData.phoneNumber;
                        projectDataObj.email = selectedLeadsData.email;
                        projectDataObj.code = selectedLeadsData.pinCode;
                        projectDataObj.city = selectedLeadsData.city;
                        dispatch(projectDataCurrObj(projectDataObj));
                      }}
                    >
                      Convert To Project{" "}
                      {!isObjectBlank(userData) &&
                        !(
                          userData?.rolesAssigned?.pmt?.canCreateProject ||
                          userData?.isSuperAdmin
                        ) && (
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip id="button-tooltip">
                                Access is disabled by Admin
                              </Tooltip>
                            }
                          >
                            <span
                              className="detailData"
                              style={{ cursor: "pointer" }}
                            >
                              <img src={LockBlack} />
                            </span>
                          </OverlayTrigger>
                        )}
                    </button>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          {filter && <Filter />}
        </div>
        <div>
          {leadsDetail && showEmailModal && <LmsMail />}
          {createProjectModalOpen && <CreateNewProjectNew currentData={selectedLeadsData} />}
        </div>
      </div>
    </>
  )
}

export default ProjectHeader
