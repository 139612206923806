import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, redirect, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./SideBar/Header";
import { DatePicker, Modal, Select, Space, Form as aForm } from "antd";
import { Modal as ModalRB } from "react-bootstrap";
import "./index.css";
import { useNavigate } from "react-router-dom";
import vector11 from "./images/Vector-11.png";
import dropdownArrow from "../../Assets/dropdownArrow.svg";
import Plus from "../../../src/Assets/Plus.svg"
import blackcheck from "../../../src/Assets/blackcheck.svg"
import greenCheck from "../../../src/Assets/greenCheck.svg"
import MagnifyingBlack from "../../../src/Assets/MagnifyingBlack.svg"

import {
  baseUrl,
  convertDateFormat,
  formateDate,
  formateDateNameMonth,
  isMd,
} from "../../piservices/compCommon";
import SideModal from "./SideModal/SideModal";
import LmsMail from "./LmsMail.js/LmsMail";
import LeadUpdate from "./LeadUpdate/LeadUpdate";
import LmsTableTh from "./LmsTableTh";
import LmsTableItemCard from "./LmsTableItemCard";
import { useEffect } from "react";
import { postReq, getReq, putReq } from "../../piservices/apis";
import expf, { constants } from "../../piservices/constants";
import desquoContext from "../../picontext/DashBoard/desquoContext";
import { useContext } from "react";
import nolead from "./images/EmptyState.svg";
import boqmv from "./images/project.svg";
import import_csv from "../../Assets/import_csv.svg";
import export_csv from "../../Assets/export_csv.svg";
import AddLeadModal from "./AddLeadModal";
import { getToken } from "../../piservices/authService";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Analytic from "./Analytics/Analytic";
import styles from "./ModuleCssFiles/lms.module.css";
import HeaderSidebar from "../Common/HeaderSidebar/HeaderSidebar";
import {
  isFilterOpen,
  setLeadSources,
  setLeadsCount,
  setPanelUpdate,
  setProfileData,
  setSelectedTabs,
  setSourceNames,
  setStoreReportees,
  setTeamLeadArr,
  setTeamMembers,
  setUserData,
  setWorkType,
} from "../../Redux/Slices/lmsSlice";
import LeadManagement from "./LeadManagements/LeadManagement";
import LeadInfoSidePanel from "./LeadManagements/LeadInfoSidePanel";
import { Dropdown, Spinner, Form as bForm } from "react-bootstrap";
import { setAddLeadModalOpen, setHeaderTab, setShowImport } from "../../Redux/Slices/newLeadSlice";
import { MagnifyingGlass, ThreeDots } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import ImportFromCSVModal from "./CSVHandlerFolder/ImportFromCSVModal.jsx";
import axios from "axios";
import useApiFetchForLeadCountCustomHook from "../../piservices/ApiFetchForLeadCount.jsx";
import useApiFetchCustomHookForEmailService from "../../piservices/ApiFetchCustomHookForEmailService.jsx";
import LeadTableV2 from "./LeadTableV2.jsx";
import { showColumnOptions } from "./constantsLMS.js";
import Pipelines from "./Pipeline/Pipelines.jsx";
import MainLandingPageAnaly from "./AnalyticsV2/MainLandingPageAnaly.jsx";
import { PiCheckSquare, PiUserCirclePlus, PiEnvelope, PiTagChevron, PiTrash } from "react-icons/pi";
import notepad from './images/notepad.png';
import folder from './images/FolderNotchPlus.png'
import { notification } from 'antd';
import useApiFetchCustomHook from "../../piservices/ApiFetchCustomHook";
import AssignLead from "./Modals/AssignLead.jsx";
import crossBold from "./images/crossBold.svg";
import downArrowGrey from "./images/downArrowGrey.svg";
import ReactQuill from "react-quill";
import { RxCross1, RxCross2 } from "react-icons/rx";
import attachedFile from "./images/attach_file_add.svg";
import close from "./images/close.svg";
import toast from "react-hot-toast";
import "./newVersion.css";
import AddLead from "../Common/NewLayout/Modals/AddLead.jsx";
import ConfirmDelete from "./Modals/ConfirmDelete.jsx";

const Lms = ({ setProgress, setSelectedtab }) => {
  let { tab } = useParams();

  const quillRef = useRef(null);
  const uploadFileRef = useRef(null);
  const profileData = useSelector((state) => state.lmsSlice.profileData);

  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState("this year");
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updatePanel, setUpdatePanel] = useState(false);
  const [updatePanel2, setUpdatePanel2] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [showAssignLead, setShowAssignedLead] = useState(false);
  const [assignLeads, setAssignLeads] = useState([]);

  // email modal
  const [openShareEmail, setOpenShareEmail] = useState(false);
  const [mailFiles, setMailFiles] = useState([]);
  const [templateName, setTemplateName] = useState("Select Template");
  const [allEmails, setAllEmails] = useState([]);
  const [tempmail, setTempmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [subjectForEmail, setSubjectForEmail] = useState("");
  const [mentionDropdownVisible, setMentionDropdownVisible] = useState(false);
  const [filteredMentions, setFilteredMentions] = useState([]);
  const [dummyfilteredMentions, setDummyFilteredMentions] = useState(filteredMentions);
  const [shareData, setShareData] = useState("");
  const [mentionQuery, setMentionQuery] = useState("");
  let toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons

    [{ list: "ordered" }, { list: "bullet" }],

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  let commonCss = {
    color: "#454545",
    fontSize: "0.875rem",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    // marginLeft: "1rem",
    cursor: "pointer",
  };

  const getAllTemplates = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const requests = [
        axios.get(`${constants.CRM_API}/api/get-all-emailTemplate?userId=${userId}&type=6`),
        axios.get(`${constants.CRM_API}/api/get-all-emailTemplate?userId=${userId}&type=4`),
        axios.get(`${constants.CRM_API}/api/get-all-emailTemplate?userId=${userId}&type=1`),
        axios.get(`${constants.CRM_API}/api/get-all-emailTemplate?userId=${userId}&type=5`)
      ];

      const responses = await Promise.all(requests);
      const combinedData = responses.flatMap(response => response?.data?.data || []);

      return combinedData;

    } catch (error) {
      console.error('Error fetching templates:', error);
      return [];
    }
  };


  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      const combinedTemplates = await getAllTemplates();
      setTemplates(combinedTemplates);
    };

    fetchTemplates();
  }, []);
  const [show, setShow] = useState(false);
  const context = useContext(desquoContext);
  const userData = useSelector((state) => state.lmsSlice.userData);
  const headerTab = useSelector((state) => state.newLeadSlice.headerTab);
  const showImport = useSelector((state) => state.newLeadSlice.showImport);
  const storeLeadCount = useSelector((state) => state.lmsSlice.storeLeadCount);
  const [createdByNameArray, setCreatedByNameArray] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const searchTerms = useSelector((state) => state.lmsSlice.searchTerms);
  const showEmailModal = useSelector((state) => state.lmsSlice.showEmailModal);
  const [searchTerm, setSearchTerm] = useState('');
  const { leads, leads2, setLeads, setLeads2, activeLead, setActiveLeadState } = context;
  const storeReportees = useSelector((state) => state.lmsSlice.storeReportees);
  const { getLeadsCount, getLeadFormTemplate } =
    useApiFetchForLeadCountCustomHook();
  const { getNotificationServices, getAllAssignToAccessUser } =
    useApiFetchCustomHookForEmailService();

  const { apiFetch } = useApiFetchCustomHook();

  const singleLeadOptions = [
    { image: <PiCheckSquare className={styles.leadOptionIcon} />, name: "Select All", command: () => { setSelectAll(true) }, style: styles.leadSelectAll },
    { image: <PiUserCirclePlus className={styles.leadOptionIcon} />, name: "Assign to", command: () => { assignLeadsHandler() }, style: styles.leadOther },
    { image: <PiEnvelope className={styles.leadOptionIcon} />, name: "Send Email", command: () => { showEmailModalHandler() }, style: styles.leadOther },
    { image: <img src={notepad} className={styles.leadOptionIcon} />, name: "Send Quotation", command: () => { createProjectAndGoToQuotation() }, style: styles.leadOther },
    { image: <img src={folder} className={styles.leadOptionIcon} />, name: "Convert to project", command: () => { window.location.assign(`${constants.BASE_URL}/pmt/?leadId=${selectedLeads[0]}`) }, style: styles.leadOther },
    // { image: <PiTagChevron className={styles.leadOptionIcon} />, name: "Add to pipeline", command: () => { }, style: styles.leadOther },
    { image: <PiTrash className={styles.leadOptionIcon} />, name: "Delete", command: () => { setShowDelete(true) }, style: styles.leadOther },
  ]

  const multipleLeadOptions = [
    {
      image: <PiCheckSquare className={styles.leadOptionIcon} style={{ strokeWidth: selectAll && "6" }} />, name: "Select All", command: () => {
        setSelectAll((prev) => !prev); // Toggle selectAll between true and false
      }, style: selectAll ? styles.leadSelectAllActive : styles.leadSelectAll
    },
    { image: <PiUserCirclePlus className={styles.leadOptionIcon} />, name: "Assign to", command: () => { assignLeadsHandler() }, style: styles.leadOther },
    { image: <PiEnvelope className={styles.leadOptionIcon} />, name: "Bulk Email", command: () => { showEmailModalHandler() }, style: styles.leadOther },
    { image: <PiTrash className={styles.leadOptionIcon} />, name: "Delete", command: () => { setShowDelete(true) }, style: styles.leadOther },
  ]

  // open add lead modal
  const showAddLead = () => {
    setShow(true);
  }

  // hide add lead modal
  const hideAddLead = () => {
    setShow(false);
  }

  // add new lead
  const addLeadHandler = () => {
    showAddLead();
  };

  //delete leads
  const deleteLeadsHandler = async () => {
    try {
      for (let i of selectedLeads) {
        await axios.post(
          `${constants.CRM_API
          }/user/edit-lead?id=${i}&userId=${localStorage.getItem("userId")}`,
          { isDeleted: true, deletedBy: localStorage.getItem("userId") }
        );
      }
      init();
    }
    catch (err) {
      console.log(err);
    }
  };

  // close delete leads warning modal
  const closeDeleteModal = () => {
    setShowDelete(false);
  };

  // for email
  const showEmailModalHandler = (data) => {
    if (data) {
      setAllEmails([data.email])
      setOpenShareEmail(true)
    }
    else {
      const emails = [];
      for (let i of selectedLeads) {
        emails.push(leads?.data[leads?.data?.findIndex(item => item._id === i)].email);
      }
      setAllEmails(emails);
      setOpenShareEmail(true);
    }
  };
  const mailTemplateHandler = (data) => {
    setTemplateName(data.name);
    setSubjectForEmail(data.subject);
    setShareData(data.text);
  };
  const deleteEmail = (email) => {
    setAllEmails(allEmails.filter(e => e !== email));
  };
  function setMailData(events) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    const form4Example1 = document.getElementById("form4Example1");
    if (events.key === "Enter") {
      if (emailPattern.test(events.target.value)) {
        setAllEmails([...allEmails, events.target.value]);
        setTempmail("");
        setEmailError(false);
        events.target.value = "";
      } else {
        if (tempmail.length > 0) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }

        form4Example1.setCustomValidity("I expect an e-mail, darling!");
        // setLeadEmail(inputValue);
        // events.target.value = ;
      }
    } else {
      if (tempmail.length == 0) {
        setEmailError(false);
      }
    }
  }
  const handleMentionSelect = (mention) => {
    const editor = quillRef.current?.getEditor();
    const mentionText = mention;
    const atIndex = shareData.lastIndexOf("@");
    const replacedText =
      shareData.slice(0, atIndex) +
      mentionText +
      shareData.slice(atIndex + mentionQuery.length + 1);

    setShareData(replacedText);
    setMentionDropdownVisible(false);
    setMentionQuery("");
  };
  const handleKeyDownTask2 = (event) => {
    if (event.key === "@") {
      setMentionDropdownVisible(true);
      setMentionQuery("");
    } else if (mentionDropdownVisible) {
      if (/[a-zA-Z]/.test(event.key)) {
        setMentionQuery((prev) => prev + event.key);
        // if (event.key === "Enter") {
        //   const mention = filteredMentions[0].fullName;
        //   const atIndex = shareData.lastIndexOf("@");
        //   const replacedText =
        //     shareData.slice(0, atIndex) +
        //     `${mention}` +
        //     shareData.slice(atIndex + mentionQuery.length + 1);

        //   setShareData(replacedText);
        // }
        // Append to query
      } else if (event.key === "Backspace") {
        setMentionQuery((prev) => prev.slice(0, -1)); // Handle backspace
      }
    }
  };
  function removeFile(index) {
    const filteredFiles = mailFiles?.filter((item, idx) => idx !== index);
    setMailFiles([...filteredFiles]);
  }
  const sendEmail = async (email) => {
    const res2 = await axios.post(
      `${constants.EMAIL_API}/api/e-service/send-email`,
      {
        subject: subjectForEmail,
        email: email,
        html: shareData,
        attachments: mailFiles,
        companyName: profileData?.CompanySettingDetails?.companyName
      }
    );
    if (res2) {
      //   setShareSheet(false)
      toast.success(`Email Sent`);
      //   window.location.reload()
      setTemplateName("Select Template");
      setShareData("");
      setMailFiles([]);
    }
  }
  const [searchQuery, setSearchQuery] = useState(""); // State to track the search input
  const [filteredTemplates, setFilteredTemplates] = useState(templates); // State to track filtered items

  // Handle search input change
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);

    // Filter templates based on the search query
    setFilteredTemplates(
      templates.filter((item) => item.name.toLowerCase().includes(value))
    );
  };

  const uploadFile = async (event) => {
    const { files } = event.target;
    for (let i = 0; i < files.length; i++) {
      let filesToUpload = new FormData();
      filesToUpload.append("files", event.target.files[i]);
      const res = await putReq(`${constants.S3_API}/api/upload`, filesToUpload);
      if (res && !res.error) {
        setMailFiles([
          ...mailFiles,
          {
            filename: files[i].name,
            path: res.data.locations[0],
          },
        ]);
      } else {
        console.log(res.error);
      }
    }
  };

  // hide 'assign lead/s to' modal
  const hideAssignLeadHandler = () => {
    setShowAssignedLead(false);
  }
  // assign lead/s
  const assignLeadsHandler = () => {
    setAssignLeads(selectedLeads);
    setShowAssignedLead(true);
  };

  // console.log(assignLeads, "alead!")

  const createProjectAndGoToQuotation = async () => {
    const activeLead = leads?.data[leads?.data?.findIndex(item => item._id === selectedLeads[0])];
    localStorage.setItem("leadId", activeLead?._id);
    if (activeLead?.projectId) {
      localStorage.setItem("projectId", activeLead?.projectId);
      window.open(
        `${constants.BASE_URL}/quotation/new-quotation/${activeLead?.projectId}?isLead=true`,
        "_blank"
      );
    } else {
      if (activeLead.email) {
        try {
          await axios
            .post(
              `${constants.PMT_API
              }/api/projects/addClient?userId=${localStorage.getItem(
                "userId"
              )}`,
              {
                email: activeLead.email,
                location: activeLead.address,
                name: activeLead.name,
                projectName: activeLead.name,
              }
            )
            .then(async (res) => {
              await axios
                .post(
                  `${constants.PMT_API
                  }/api/projects/add-project?userId=${localStorage.getItem(
                    "userId"
                  )}`,
                  {
                    clientId: res.data._id,
                    location: activeLead.address,
                    projectName: activeLead.name,
                    userId: localStorage.getItem("userId"),
                    isConfirmed: false,
                    phoneNumber: activeLead.phoneNumber,
                    clientName: activeLead.name,
                    isFromLead: true,
                    projectAdmin: activeLead?.assignedTo?._id,
                    teamMembers: [activeLead?.assignedTo?._id],
                  }
                )
                .then(async (response) => {
                  localStorage.setItem("projectId", response?.data?._id);
                  await setActiveLeadState({
                    ...activeLead,
                    projectId: response?.data?._id,
                  });
                  await postReq(
                    `${constants.CRM_API}/api/save-projectId-leads?leadId=${activeLead?._id}&projectId=${response?.data?._id}`
                  )
                    .then((res2) => {
                      apiFetch(tab);
                      window.open(
                        `${constants.BASE_URL}/quotation/new-quotation/${response.data._id}?isLead=true`,
                        "_blank"
                      );
                    })
                    .catch((err) => {
                      notification.error({
                        message: err.error || "client already exist",
                        duration: 1,
                      });
                    });
                })
                .catch((e) => {
                  notification.error({
                    message: e.error || "project already exists",
                    duration: 1,
                  });
                })
                .catch((err) => {
                  notification.error({
                    message: err.error || "client already exists",
                    duration: 1,
                  });
                });
            });
        } catch (e) {
          notification.error({
            message: e.error || "client already exists",
            duration: 1,
          });
        }
      } else {
        // setCurrentQuot("desQuot");
        // handleCloseModal();
        // handleOk2();
      }
    }
  };


  const init = async () => {
    setLoading(true);
    await setProgress(30);

    const res = await getReq(
      constants.CRM_API +
      "/user/newLeadsData?pageNo=0" +
      (tab > 0 ? "&type=" + tab : "")
    );

    if (res && !res.error) {
      if (tab === "2") {
        let today = new Date();

        // Filter for leads before today
        let followUpLeadsBeforeToday = res?.data?.data?.data?.filter(
          (item) =>
            today >
            new Date(
              convertDateFormat(
                item?.leadUpdates[item?.leadUpdates?.length - 1].updateDate
              )
            )
        );
        // Filter for leads today and after
        let followUpLeadsTodayAndAfter = res?.data?.data?.data?.filter(
          (item) =>
            today <=
            new Date(
              convertDateFormat(
                item?.leadUpdates[item?.leadUpdates?.length - 1].updateDate
              )
            )
        );

        // Combine both filtered lists
        let x = JSON.parse(JSON.stringify(res?.data?.data));
        delete x["data"];
        x["data"] = [
          ...followUpLeadsTodayAndAfter,
          ...followUpLeadsBeforeToday,
        ];

        setLeads(x);
        setLeads2(x);
      } else {
        // Remove sorting from the general case
        let x = JSON.parse(JSON.stringify(res?.data?.data));
        setLeads({ ...x });
        setLeads2(x);
      }

      setLoading(false);
    }

    await setProgress(100);
  };
  function filterLeads(searchTerms) {
    let filteredLeads = leads2?.data?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTerms?.toLowerCase())
    );
    if (leads2?.data) {
      let x = leads2;
      setLeads({ ...x, data: [...filteredLeads] });
    }
  }

  useEffect(() => {
    filterLeads(searchTerms);
  }, [searchTerms]);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };
  useEffect(() => {
    const handleClickOutsideNew = (event) => {
      if (
        dropdownContainerRef.current &&
        !dropdownContainerRef.current.contains(event.target)
      ) {
        setDropdownOpen(false); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutsideNew);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNew);
    };
  }, []);

  const getLeadSource = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      dispatch(setLeadSources(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };

  const getTeamLeads = async () => {
    const res = await getReq(
      `${constants.CRM_API
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      dispatch(setTeamLeadArr(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  const getMembers = async () => {
    const res = await getReq(
      `${constants.CRM_API
      }/user/get-reportees?userId=${localStorage.getItem(
        "userId"
      )} `
    );
    if (res && !res.error) {
      dispatch(setStoreReportees(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  useEffect(() => {
    getMembers();
  }, [])
  const getWorkTypes = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      dispatch(setWorkType(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  const getProfileData = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/profile`,
      {},
      { Authorization: `Bearer ${getToken()}` }
    );
    if (res && !res.error) {
      dispatch(setProfileData(res?.data?.data));
    } else {
      // console.log(res.error);
      // localStorage.clear();
      // window.location.assign(`${constants. BASE_URL}`);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${constants.CRM_API}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      dispatch(setSourceNames(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(
      `${constants.CRM_API}/user/getUserById?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      dispatch(setUserData(res?.data?.data));
    } else {
      console.log(res.error);
    }
  };
  const getTeamMember = async () => {
    await axios
      .get(
        `${constants.CRM_API
        }/api/enterprise/get-team-member?userId=${localStorage.getItem(
          "userId"
        )}`
      )
      .then(function (response) {
        dispatch(setTeamMembers(response.data.data));
        // setteamMember(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };



  const getUserName = async (projectId) => {
    try {
      const res = await getReq(
        `${constants.PMT_API}/api/projects/get-project-byId?projectId=${projectId}`
      );
      return res?.data?.createdByName || null;
    } catch (error) {
      console.error(`Error fetching data for projectId ${projectId}:`, error);
      return null;
    }
  };

  // Function to fetch names for all projects
  // useEffect(() => {
  //   const fetchNames = async () => {
  //     if (!leads || !leads.data) return; // Exit if leads data is not available

  //     const namesArray = [];
  //     for (const lead of leads.data) {
  //       const { projectId } = lead;

  //       // Only make API call if projectId is defined
  //       if (projectId) {
  //         try {
  //           const createdByName = await getUserName(projectId);
  //           namesArray.push(createdByName);
  //           lead.createdByName = createdByName;
  //         } catch (error) {
  //           console.error(`Error fetching data for projectId ${projectId}:`, error);
  //         }
  //       }
  //     }
  //     setCreatedByNameArray(namesArray);
  //   };

  //   fetchNames();
  // }, [leads]); // Depend on leads


  const csvHeader = [
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Lead status",
    "Expected Closure",
    "Budget",
    "Scope",
    "Address",
    "City",
    "Area",
    "Pin Code",
    "Lead Source",
    "Source Name",
    "Assigned To",
    "Last Call Date",
    "Convert to Project on Date",
    "Follow up Date and Time",
    "Converted By ",
    "Last Call Status",
  ];

  // const csvData = leads?.data.map(
  //   (
  //     {
  //       name,
  //       firstName,
  //       lastName,
  //       email,
  //       phoneNumber,
  //       leadStatus,
  //       clouserMonth,
  //       budget,
  //       requirements,
  //       address,
  //       city,
  //       area,
  //       pinCode,
  //       leadSource,
  //       sourceName,
  //       assignedTo,
  //       leadUpdates,
  //       createdByName,
  //     },
  //     i
  //   ) => [
  //       firstName ? firstName : name,
  //       lastName ? lastName : "",
  //       email,
  //       phoneNumber,
  //       leadStatus !== 1
  //         ? leadStatus === 2
  //           ? "Follow Up"
  //           : leadStatus === 3
  //             ? "Interested"
  //             : leadStatus === 5
  //               ? "Not Interested"
  //               : leadStatus === 6
  //                 ? "No Response"
  //                 : ""
  //         : "New",
  //       clouserMonth ? clouserMonth : "",
  //       budget,
  //       requirements,
  //       address,
  //       city,
  //       area,
  //       pinCode,
  //       leadSource,
  //       sourceName,
  //       assignedTo?.fullName,

  //       leadUpdates[leadUpdates?.length - 1]?.updateDate,
  //       leadUpdates[leadUpdates?.length - 1]?.updateDate,

  //       leadUpdates[leadUpdates?.length - 1]?.updateDate == undefined
  //         ? ""
  //         : leadUpdates[leadUpdates?.length - 1]?.updateDate +
  //         ", " +
  //         leadUpdates[leadUpdates?.length - 1]?.updateTime,
  //       createdByNameArray[i],
  //       leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 1
  //         ? "New"
  //         : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 2
  //           ? "Follow Up"
  //           : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 3
  //             ? "Interested"
  //             : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 5
  //               ? "Not Interested"
  //               : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 6
  //                 ? "No Response"
  //                 : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 7
  //                   ? "Meeting"
  //                   : leadUpdates[leadUpdates?.length - 1]?.callLeadStatus === 4
  //                     ? "Sign Up"
  //                     : "",
  //     ]
  // );
  useEffect(() => {
    getLeadSource();
    getTeamLeads();
    getSourceName();
    getProfileData();
    getWorkTypes();
    getTeamMember();
    getUserbyId();
  }, []);

  useEffect(() => {
    getNotificationServices();
    getLeadFormTemplate();
    getAllAssignToAccessUser();
    init();
    getLeadsCount();
    if (!getToken()) {
      // localStorage.clear();
      // window.location.href = "/";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, updatePanel, localStorage.getItem("headerTab")]);
  const [showScroll, setShowScroll] = useState(false);
  const [showOptionDropdown, setShowOptionDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownContainerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptionDropdown(false); // Close dropdown only if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOption = () => {
    setShowOptionDropdown(!showOptionDropdown);
  };
  const [tableOptions, setTableOptions] = useState({
    // Source: false,
    // "Assigned To": false,
    // Tags: false,
    ["Lead Name"]: true, ["Address"]: true, ["Mobile No."]: true, ["Email address"]: true, ["City"]: true, ["Budget"]: true, ["Work Scope"]: true, ["Area"]: true, ["Stage"]: true, ["Last Call Status"]: true, ["Last Call"]: true, ["Follow up"]: true, ["Exp. Closure"]: true, ["Assigned To"]: true, ["Lead Source"]: true, ["Source Name"]: true
  });

  const [defaultFields, setDefaultFields] = useState(["Lead Name", "Mobile No.", "Email address", , "Stage"]);

  const checkboxOption = (data) => {
    setTableOptions((prev) => ({
      ...prev,
      [data]: !prev[data],
    }));
  };
  const [showDateModal, setShowDateModal] = useState(false);
  const nextPanel = useSelector((state) => state.lmsSlice.nextPanel);
  const leadsCount = useSelector((state) => state.lmsSlice.leadsCount);
  const isFilterOpens = useSelector((state) => state.lmsSlice.isFilterOpen);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [form] = aForm.useForm();
  const [widthOfPanel, setWidthOfPanel] = useState(
    window.screen.width > 1341 ? "22.5rem" : "18.5rem"
  );
  const updateMedia = () => {
    if (window.screen.width >= 1341) {
      setWidthOfPanel("22.5rem");
    } else {
      setWidthOfPanel("18.5rem");
    }
  };
  const handleChange = (value) => {
    if (value === "custom") {
      form.resetFields();
      setShowDateModal(true);
      setShowFilterDropdown(false);
    }

    if (value !== "custom") {
      setSelectedPeriod(value);
    }
  };
  const handleDateSubmit = () => {
    setShowDateModal(false);
    setSelectedPeriod("custom");
  };
  useEffect(() => {
    localStorage.removeItem("visited");
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // const handleBoq = async (data) => {
  //   try {
  //     await setProgress(30);
  //     const res = await postReq(constants.QUO_API + "/api/quick_quotation/add_new_project", {
  //       "isLead": false,
  //       "projectName": data.name,
  //       "projectLocation": selectedLead.city,
  //       "name": selectedLead.name,
  //       "emailId": selectedLead.email,
  //       "location": selectedLead.city,
  //       "config": selectedLead.config,
  //       "budget": selectedLead.budget,
  //       "requirements": selectedLead.propertyType,
  //       "renovationTimeline": selectedLead.renovationTimeline,
  //       "designerId": getLoginId()
  //     });
  //     if (res && !res.error) {
  //       navigate("/quick-quotation/req/" + res?.data?._id);
  //     }
  //     await setProgress(100);
  //   } catch (error) {init
  //     if (error.response && error.response.status === 400) {
  //       toast.error(error.response.data, {
  //         position: "bottom-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   }
  // };

  // document.onscroll= () => {
  //   alert("Hi");
  // }
  const getDateStr2 = (dt) => {
    let date = new Date(dt);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dte = date.getDate();
    if (dte < 10) {
      dte = "0" + dte;
    }
    let mon = monthNames[date.getMonth()];
    let yr = date.getFullYear() + "";
    let dateStr = dte + " " + mon + " " + yr.slice(2, 4);
    return dateStr;
  };
  setInterval(function () {
  }, 1 / 100);
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [storeMem, setStoreMem] = useState("All");
  const handleSelect = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds([]);
    } else {
      setSelectedIds([id]);
      setIsAllSelected(false);
    }
  };
  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds(storeReportees.map((data) => data._id));
    }
    setIsAllSelected(!isAllSelected);
  };
  const filteredReportees = storeReportees.filter((data) =>
    data.fullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClearSelection = () => {
    setSelectedIds([]);
    setIsAllSelected(false);
  };


  return (
    <div id="body">
      <ToastContainer limit={1} />
      <ImportFromCSVModal showImport={showImport} />
      {/* handleClose={handleClose} */}
      <Modal
        title=""
        style={{
          left: 400,
          top: 200,
        }}
        open={showDateModal}
        onOk={handleDateSubmit}
        width={200}
        onCancel={() => {
          setShowDateModal(false);
        }}
      >
        <div className="">
          <div className="m-2">
            <div>Start Date</div>
            <DatePicker
              onChange={(_, dateStr) => {
                setStartDate(dateStr);
              }}
            />
          </div>
          <div className="m-2">
            <div>End Date</div>
            <DatePicker
              onChange={(_, dateStr) => {
                setEndDate(dateStr);
              }}
            />
          </div>
        </div>
      </Modal>
      <AssignLead show={showAssignLead} hide={hideAssignLeadHandler} leadsToChange={assignLeads} />
      <AddLead show={show} hide={hideAddLead} />
      <ConfirmDelete
        show={showDelete}
        hide={closeDeleteModal}
        name="Lead"
        setSelectedIds={setSelectedIds}
        action={deleteLeadsHandler}
      />
      <input
        className="d-none"
        ref={uploadFileRef}
        onChange={uploadFile}
        // multiple
        type="file"
        name=""
        id=""
      />

      {/* <Header sideClass="d-none d-lg-block" /> */}
      {loading ? (
        <div
          className=""
          style={{
            position: "relative",
            marginTop: "50vh",
            marginLeft: "64vw",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#0084EF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div
          className="das-main-box"
          onScroll={() => {
            alert("Hi");
          }}
        >
          <div
            className="d-flex flex-wrap bg-white h-100"
            style={
              {
                // paddingTop:'1rem'
              }
            }
          >
            <div
              className="w-100 mv-w-100 "
              style={{ marginTop: isFilterOpens ? "10rem" : null }}
            >
              <div
                className="overflow-auto"
                style={{
                  // height: isMd && window.innerHeight - 100,
                  height: "89vh",
                }}
              >
                <div className="d-flex flex-column">
                  {/* <div className="d-flex justify-content-between align-items-center w-100">
                <h5 className=" d-none d-md-block px-md-4 mb-0 pb-0">
                  Lead Management
                </h5>
                <div className=" align-items-center pt-3 d-none d-md-flex">
                  <button
                    className="btn btn-primary mv-w-100 bg-base-blue box-shadow-none pt-2"
                    data-mdb-toggle="modal"
                    data-mdb-target="#addLeadModal"
                  >
                    Add Lead
                  </button>
                </div>
              </div> */}
                  {/* <div
                className=" px-3 overflow-auto scb-none px-md-4 mt-3 mt-md-0 mv-position-sticky top-0 bg-white"
                style={{
                  zIndex: 1,
                  // minHeight : "100px"
                }}
              > */}
                </div>
                {/* <div
              className="mt-0 w-100 bg-grey1"
              style={{
                height: isMd ? "10px" : "1px",
              }}
            /> */}

                {/*  when leads have no data  */}

                {leads?.data?.length === 0 && (
                  <div
                    className="w-100 text-center my-5 m-auto"
                    style={{
                      maxWidth: "500px",
                      height: "80%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {headerTab === "Leads Management" && (
                      <div
                        className={`cursor-pointer start-0 scb-none  pitabs pfs-14 align-items-center mb-3 ${styles.sideBarPanel}`}
                        style={{
                          marginTop:
                            window.screen.width < 1400 ? "-29rem" : "-29rem",
                        }}
                      >
                        <div style={{ display: "flex", gap: "10px" }}>
                          {/* <Link to={`/leads/`} className={`me-2 px-2 ws-nowrap ${!tab ? "cl-base-blue blue-bottom" : "cl-grey1"} mb-0 pb-2 p-hover`}> */}
                          <Link
                            to={`/leads/`}
                            onClick={(e) => { e.preventDefault(); setUpdatePanel2(!updatePanel2); window.location.href = `/leads/` }}
                            className={` ws-nowrap ${!tab || tab == "0"
                              ? styles.activeTab
                              : styles.inactiveTab
                              } mb-2 p-hover`}
                          >
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "8rem",
                              }}
                            >
                              <div>All</div>
                              <div className="countBox">
                                {(!tab || tab == "0") ? storeLeadCount : leadsCount.all}</div>
                            </div>
                          </Link>
                          {/* <Link
                          to={`/leads/3`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${tab === "3" ? styles.activeTab : styles.inactiveTab
                            } mb-2 p-hover`}
                        >
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>Interested</div>
                            <div className="countBox">
                              {leadsCount?.interested}
                            </div>
                          </div>
                        </Link>
                        <Link
                          to={`/leads/2`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${tab === "2" ? styles.activeTab : styles.inactiveTab
                            } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>Follow Up</div>
                            <div className="countBox">
                              {leadsCount?.followUp}
                            </div>
                          </div>
                        </Link>
                        <Link
                          to={`/leads/6`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${tab === "6" ? styles.activeTab : styles.inactiveTab
                            } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>No Response</div>
                            <div className="countBox">
                              {leadsCount?.noResponse}
                            </div>
                          </div>
                        </Link> */}
                          {/* <Link
                          to={`/leads/5`}
                          onClick={() => setUpdatePanel2(!updatePanel2)}
                          className={` ws-nowrap ${tab === "5" ? styles.activeTab : styles.inactiveTab
                            } mb-2 p-hover`}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div>Not Interested</div>
                            <div className="countBox">
                              {leadsCount?.notInterested}
                            </div>
                          </div>
                        </Link> */}
                          {console.log(storeLeadCount, "wedcwecwec")}
                          <Link
                            onClick={(e) => { e.preventDefault(); setUpdatePanel2(!updatePanel2); window.location.href = `/leads/9` }}
                            to={`/leads/9`}
                            className={` ws-nowrap ${tab === "9" ? styles.activeTab : styles.inactiveTab
                              } mb-2 p-hover`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                gap: "10px"
                              }}
                            >
                              <div>Won</div>
                              <div className="countBox"> {tab == "9" ? storeLeadCount : leadsCount.won}</div>
                            </div>
                          </Link>
                          {userData?.rolesAssigned?.crm?.canAssign && (
                            <Link
                              onClick={(e) => { e.preventDefault(); setUpdatePanel2(!updatePanel2); window.location.href = `/leads/8` }}
                              to={`/leads/8`}
                              className={` ws-nowrap ${tab === "8"
                                ? styles.activeTab
                                : styles.inactiveTab
                                } mb-2 p-hover`}
                            // style={{ marginTop: "-29rem" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div>Unassigned</div>
                                <div className="countBox">
                                  {tab == "8" ? storeLeadCount : leadsCount.unassigned}
                                </div>
                              </div>
                            </Link>
                          )}
                        </div>
                      </div>
                    )}

                    <div style={{ position: "fixed", top: "28%", left: "40%" }}>
                      <div
                        className="d-flex w-100 pb-2 pt-2"
                        style={{
                          position: "fixed",
                          left: headerTab == "Analytics" ? "0%" : "14.5%",
                        }}
                      >
                        {leads?.data?.length == 0 && (
                          <div className="pb-0">
                            <div
                              className={` ${styles.tabs__section}`}
                              style={{
                                width:
                                  headerTab == "Analytics" ? "93vw" : "85vw",
                                top: "7rem",
                                marginLeft:
                                  headerTab == "Analytics" ? "4.5%" : "",
                              }}
                            >
                              {/* <div className="d-flex" style={{ gap: "10px" }}>
                                <div
                                  className={`${styles.tabDim2} ${
                                    headerTab === "Leads Management"
                                      ? styles.tab__selected
                                      : styles.tab__not__selcted
                                  }`}
                                  onClick={() => {
                                    setSelectedtab("Leads Management");
                                    localStorage.setItem(
                                      "headerTab",
                                      "Leads Management"
                                    );
                                    dispatch(
                                      setSelectedTabs("Leads Management")
                                    );
                                    dispatch(setHeaderTab("Leads Management"));
                                    setSelectedHeaderTab("Leads Management");
                                  }}
                                >
                                  Lead Management
                                </div>
                                <div
                                  className={`${styles.tabDim1} ${
                                    headerTab === "Analytics"
                                      ? styles.tab__selected
                                      : styles.tab__not__selcted
                                  } ${styles.marginLeft}`}
                                  onClick={() => {
                                    setSelectedtab("Analytics");
                                    dispatch(setSelectedTabs("Analytics"));
                                    localStorage.setItem(
                                      "headerTab",
                                      "Analytics"
                                    );
                                    dispatch(setHeaderTab("Analytics"));
                                    dispatch(isFilterOpen(false));
                                    setSelectedHeaderTab("Analytics");
                                  }}
                                >
                                  Analytics
                                </div>
                              </div> */}
                              {/* {headerTab === "Leads Management" && (
                                <div className={styles.rightTabContainer}>
                                  <div
                                    className={`${styles.import_csv} ${styles.marginLeft}`}
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={()=>{dispatch(setShowImport(true))}}
                                  >
                                    <span className={styles.csv_text}>
                                      Import
                                    </span>{" "}
                                    <img
                                      style={{ marginLeft: "5px" }}
                                      src={import_csv}
                                    />
                                  </div>
                                  <div>
                                    <CSVLink
                                      filename="lead_data.csv"
                                      data={[csvHeader, ...csvData]}
                                    >
                                      <div
                                        className={` ${styles.export_csv}  ${styles.marginLeft}`}
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <span className={styles.csv_text}>
                                          Convert to CSV{" "}
                                        </span>{" "}
                                        <img
                                          style={{ marginLeft: "5px" }}
                                          src={export_csv}
                                        />
                                      </div>
                                    </CSVLink>
                                  </div>
                                </div>
                              )} */}

                              {/* {headerTab === "Analytics" && (
                                <div>
                                  <div>All</div>
                                <div>
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "12.5px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <span style={{ marginRight: "5px" }}>
                                      From :
                                    </span>
                                    <span>{getDateStr2(startDate)}</span>
                                    <span style={{ margin: "5px" }}>-</span>
                                    <span>{getDateStr2(endDate)}</span>
                                  </span>
                                  <Space wrap>
                                    <Select
                                      bordered={true}
                                      defaultValue="this quarter"
                                      value={
                                        selectedPeriod == ""
                                          ? "this quarter"
                                          : selectedPeriod
                                      }
                                      style={{
                                        width: 120,
                                      }}
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: "this week",
                                          label: "This Week",
                                        },
                                        {
                                          value: "this month",
                                          label: "This month",
                                        },
                                        {
                                          value: "this quarter",
                                          label: "This Quarter",
                                        },
                                        {
                                          value: "this year",
                                          label: "This Year",
                                        },
                                        {
                                          value: "last week",
                                          label: "Last Week",
                                        },
                                        {
                                          value: "last month",
                                          label: "Last Month",
                                        },
                                        {
                                          value: "last quarter",
                                          label: "Last Quarter",
                                        },
                                        {
                                          value: "last year",
                                          label: "Last Year",
                                        },
                                      ]}
                                    />
                                  </Space>
                                </div>
                                </div>
                              )} */}
                              { }
                            </div>

                            {/* {headerTab === "Leads Management" && (
                                <LeadManagement
                                  updatePanel={updatePanel}
                                  setUpdatePanel={setUpdatePanel}
                                  rerender={rerender}
                                  setRerender={setRerender}
                                />
                              )} */}
                            {/* {headerTab === "Analytics" && (
                              <Analytic
                                selectedPeriod={selectedPeriod}
                                dates={dates}
                                value={value}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                              />
                            )} */}

                          </div>
                        )}
                      </div>
                      {/* {headerTab === "Leads Management" && ( */}
                      <img
                        src={nolead}
                        alt=""
                        srcSet=""
                        width={300}
                        style={{
                          maxWidth: "60vw",
                        }}
                      />
                      {/* )} */}
                    </div>

                    {/* {headerTab === "Leads Management" && ( */}
                    <div
                      style={{ position: "fixed", top: "62%", left: "43%" }}
                    >
                      <div className="d-flex w-100 mt-3 flex-column align-items-center">
                        <p className="h5 cl-#4A4A4A fw-bold mb-3" style={{ marginTop: "1rem" }}>
                          No Lead(s) to Show
                        </p>
                        {headerTab === "Leads Management" ? <p className="h6 cl-black fw-normal mb-2">
                          You don't have any leads yet.
                          <br />
                          Start by adding a new lead.
                        </p> : headerTab === "Pipelines" ?
                          <div style={{ fontSize: "16px", fontWeight: "400", color: "#4A4A4A", marginBottom: "1rem" }}>Add Lead(s), to view Pipeline</div>
                          : <div style={{ fontSize: "16px", fontWeight: "400", color: "#4A4A4A", marginBottom: "1rem" }}>Add Lead(s), to view Team’s Analytics </div>
                        }
                        <button
                          style={{
                            borderRadius: "0.5rem",
                            color: "#fff",
                            border: "1px solid #0084EF",
                            background: "#0084EF",
                            height: "2.185rem",
                          }}
                          className="mv-w-100 box-shadow-none border-none"
                          onClick={() => dispatch(setAddLeadModalOpen(true))}
                        >
                          Add Lead(s)
                        </button>
                      </div>
                    </div>
                    {/* )} */}

                  </div>
                )}

                {/*  when leads is more than 1  */}

                <div>
                  {
                    // !isMd ? (
                    !loading && (
                      <div style={{ display: "flex" }}>
                        {/* {headerTab === "Analytics" && (
                          <div
                            style={{ width: "2vw" }}
                            className={`cursor-pointer scb-none pitabs pfs-14 border-0 align-items-center mb-3 ${styles.sideBarPanel}`}
                          ></div>
                        )} */}
                        {leads?.data?.length > 0 &&
                          headerTab === "Leads Management" &&
                          <div
                            className={`cursor-pointer scb-none pitabs pfs-14 align-items-center  ${styles.sideBarPanel}`}
                            style={{ backgroundColor: "white" }}
                          >
                            {/* <Link to={`/leads/`} className={`me-2 px-2 ws-nowrap ${!tab ? "cl-base-blue blue-bottom" : "cl-grey1"} mb-0 pb-2 p-hover`}> */}
                            {selectedLeads.length === 0 ?
                              <div className={styles.stages}>
                                <Link
                                  to={`/leads/`}
                                  onClick={(e) => { e.preventDefault(); setUpdatePanel2(!updatePanel2); window.location.href = `/leads/` }}
                                  className={` ws-nowrap ${!tab || tab == "0"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                    } mb-2 p-hover`}
                                >
                                  <div
                                    style={{
                                      //marginBottom:"16px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "8rem",
                                    }}
                                  >
                                    <div>All</div>
                                    <div className="countBox">
                                      {(!tab || tab == "0") ? storeLeadCount : leadsCount.all}
                                    </div>
                                  </div>
                                </Link>
                                {/* <Link
                                to={`/leads/3`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  tab === "3"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                {" "}
                                <div
                                  style={{
                                    //marginBottom:"16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Interested </div>
                                  <div className="countBox">
                                    {leadsCount?.interested}
                                  </div>
                                </div>
                              </Link> */}
                                {/* <Link
                                to={`/leads/2`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  tab === "2"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    //marginBottom:"16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Follow Uap</div>
                                  <div className="countBox">
                                    {leadsCount?.followUp}
                                  </div>
                                </div>
                              </Link> */}
                                {/* <Link
                                to={`/leads/6`}
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                className={` ws-nowrap ${
                                  tab === "6"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    //marginBottom:"16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>No Response</div>
                                  <div className="countBox">
                                    {leadsCount.noResponse}
                                  </div>
                                </div>
                              </Link> */}
                                {/* <Link
                                onClick={() => setUpdatePanel2(!updatePanel2)}
                                to={`/leads/5`}
                                className={` ws-nowrap ${
                                  tab === "5"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                } mb-2 p-hover`}
                              >
                                <div
                                  style={{
                                    //marginBottom:"16px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div>Not Interested</div>
                                  <div className="countBox">
                                    {leadsCount?.notInterested}
                                  </div>
                                </div>
                              </Link> */}
                                <Link
                                  onClick={(e) => { e.preventDefault(); setUpdatePanel2(!updatePanel2); window.location.href = `/leads/9` }}
                                  to={`/leads/9`}
                                  className={` ws-nowrap ${tab === "9"
                                    ? styles.activeTab
                                    : styles.inactiveTab
                                    } mb-2 p-hover`}
                                >
                                  <div
                                    style={{
                                      //marginBottom:"16px",
                                      display: "flex",
                                      gap: "10px",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <div>Won</div>
                                    <div className="countBox">
                                      {tab == "9" ? storeLeadCount : leadsCount.won}
                                    </div>
                                  </div>
                                </Link>
                                {userData?.rolesAssigned?.crm?.canAssign && (
                                  <Link
                                    to={`/leads/8`}
                                    onClick={(e) => { e.preventDefault(); setUpdatePanel2(!updatePanel2); window.location.href = `/leads/8` }}
                                    className={` ws-nowrap ${tab === "8"
                                      ? styles.activeTab
                                      : styles.inactiveTab
                                      } mb-2 p-hover`}
                                  // style={{ marginTop: "80%" }}
                                  >
                                    <div
                                      style={{
                                        //marginBottom:"16px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "8rem",
                                      }}
                                    >
                                      <div>Unassigned</div>
                                      <div className="countBox">
                                        {tab == "8" ? storeLeadCount : leadsCount.unassigned}
                                      </div>
                                    </div>
                                  </Link>
                                )}
                              </div>
                              :
                              <div className={styles.leadSelectedMain}>
                                <div className={styles.leadsSelected}>
                                  {selectedLeads.length} Selected
                                </div>
                                <div className={styles.leadOptions}>
                                  {selectedLeads.length === 1 ? singleLeadOptions?.map(item => <div className={item.style} onClick={item.command}>
                                    {item.image}
                                    <div>{item.name}</div>
                                  </div>) : multipleLeadOptions?.map(item => <div className={item.style} onClick={item.command}>
                                    {item.image}
                                    <div>{item.name}</div>
                                  </div>)}
                                </div>
                              </div>}
                            <div>
                              <div
                                onClick={toggleOption}
                                style={{
                                  // marginBottom: "0.5rem",
                                  // marginLeft: "40rem",
                                  background: "white",
                                  border: "1px solid #DEDEDE",
                                  display: "flex",
                                  width: "161px",
                                  borderRadius: "8px",
                                  padding: "8px 12px",
                                  gap: "8px",
                                  float: "right",
                                  marginRight: "2.4rem",
                                }}
                              >
                                <div style={{ display: "flex" }}>
                                  <img src={Plus} alt="Plus Icon" />
                                  <div
                                    style={{
                                      color: "#777",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      width: "150px",
                                    }}
                                  >
                                    Show/Hide Columns
                                  </div>
                                </div>
                              </div>

                              <Dropdown
                                // style={{ position: "unset" }}
                                style={{ marginRight: "16.3rem" }}
                                show={showOptionDropdown}
                              >
                                <Dropdown.Menu
                                  ref={dropdownRef}
                                  style={{
                                    position: "absolute",
                                    width: "14rem",
                                    opacity: 1,
                                    overflowY: "scroll",  // Ensures vertical scrolling when needed
                                    maxHeight: "300px", // Set a max height to trigger scrolling
                                    marginRight: "3vw",
                                    float: "right",
                                    marginTop: "2.3rem",
                                  }}
                                >
                                  {
                                    showColumnOptions?.map((optionItems, optionIndex) => (
                                      <Dropdown.Item
                                        onClick={() => {
                                          if (defaultFields.indexOf(optionItems?.label) === -1) {
                                            checkboxOption(`${optionItems?.label}`);
                                          }
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontSize: "12px",
                                            fontWeight: "500",
                                            gap: "4px",
                                          }}
                                        >
                                          <div style={{ color: defaultFields.indexOf(optionItems?.label) !== -1 && "#717789" }}>{optionItems?.label}</div>
                                          {/* <img src={blackcheck} alt="" /> */}
                                          <input type="checkbox" style={{ pointerEvents: "none", cursor: "pointer" }} checked={tableOptions[`${optionItems?.label}`]} disabled={defaultFields.indexOf(optionItems?.label) !== -1} />
                                        </div>
                                      </Dropdown.Item>
                                    ))
                                  }
                                  {/* <Dropdown.Item
                                    onClick={() => {
                                      checkboxOption("Source");
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        gap: "4px",
                                      }}
                                    >
                                      <div>Source</div>
                                      {tableOptions["Source"] && (
                                        <img src={blackcheck} alt="" />
                                      )}
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      checkboxOption("Assigned To");
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        gap: "4px",
                                      }}
                                    >
                                      <div>Assigned To</div>
                                      {tableOptions["Assigned To"] && (
                                        <img src={blackcheck} alt="" />
                                      )}
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      checkboxOption("Tags");
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        fontSize: "12px",
                                        fontWeight: "500",
                                        gap: "4px",
                                      }}
                                    >
                                      <div>Tags</div>
                                      {tableOptions["Tags"] && (
                                        <img src={blackcheck} alt="" />
                                      )}
                                    </div>
                                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        }

                        <div
                          className="d-flex w-100 pb-2 pt-2"
                          style={
                            {
                              //   position: "fixed",
                              // paddingLeft: headerTab == "Analytics" ? "8%" : "14.5%",
                              // , paddingLeft:headerTab == "Analytics" ? "8%" : "14.5%", marginLeft:'3%'
                            }
                          }
                        >
                          {leads?.data?.length > 0 && (
                            <div style={{ width: headerTab === "Leads Management" && "100%" }}>
                              {headerTab === "Leads Management" && (
                                <LeadTableV2
                                  tableOptions={tableOptions}
                                  updatePanel={updatePanel}
                                  setUpdatePanel={setUpdatePanel}
                                  rerender={rerender}
                                  setRerender={setRerender}
                                  setSelectedLeadsProp={setSelectedLeads}
                                  showEmailModalHandler={showEmailModalHandler}
                                  selectAll={selectAll}
                                  setSelectAll={setSelectAll}
                                  init={init}
                                />
                              )}
                              {headerTab === "Leads Management" && leads?.data?.length === 0 && (
                                <>
                                  <div className="zero_State mt-3">
                                    <img
                                      src="../../image_zeroState.svg"
                                      alt=""
                                      srcSet=""
                                      width={150}
                                      style={{
                                        maxWidth: "60vw",
                                        marginLeft: "1rem"
                                      }}
                                    />
                                    <p className="h6 cl-black fw-normal mb-2 align-items-center" style={{ marginLeft: "-2rem" }}>
                                      No leads to display. Click here to Add Lead
                                      {/* <br />
                       Start by adding a new lead. */}
                                    </p>
                                    <button
                                      style={{
                                        borderRadius: "0.5rem",
                                        color: "#fff",
                                        border: "1px solid #0084EF",
                                        background: "#0084EF",
                                        height: "2.185rem",
                                        marginTop: ".5rem",
                                        marginLeft: "2rem"
                                      }}
                                      className="mv-w-100 box-shadow-none border-none"
                                      // onClick={() => dispatch(setAddLeadModalOpen(true))}
                                      onClick={addLeadHandler}
                                    >
                                      Add Lead
                                    </button>


                                  </div>
                                </>
                              )}
                            </div>
                          )}

                          {leads?.data?.length > 0 && (
                            <div className="pb-0">
                              <div
                                // className={` ${styles.tabs__section}`}
                                style={{
                                  width:
                                    // headerTab == "Analytics" ? "93vw" : "85vw",
                                    headerTab == "Analytics" && "93vw",
                                  marginLeft:
                                    // headerTab == "Analytics" ? "4.5%" : "14.5%",
                                    headerTab == "Analytics" && "4.5%",
                                }}
                              >
                                {/* {headerTab === "Leads Management" && (
                                  <div className={styles.rightTabContainer}>
                                    <div
                                      className={`${styles.import_csv} ${styles.marginLeft}`}
                                      style={{ whiteSpace: "nowrap" }}
                                      onClick={()=>{dispatch(setShowImport(true))}}
                                    >
                                      <span className={styles.csv_text}>
                                        Importz
                                      </span>{" "}
                                      <img
                                        style={{ marginLeft: "5px" }}
                                        src={import_csv}
                                      />
                                    </div>

                                    <div>
                                      <CSVLink
                                        filename="lead_data.csv"
                                        data={[csvHeader, ...csvData]}
                                      >
                                        <div
                                          className={` ${styles.export_csv}  ${styles.marginLeft}`}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          <span className={styles.csv_text}>
                                            Convert to CSV
                                          </span>{" "}
                                          <img
                                            style={{ marginLeft: "5px" }}
                                            src={export_csv}
                                          />
                                        </div>
                                      </CSVLink>
                                    </div>
                                  </div>
                                )} */}
                                {headerTab === "Analytics" && (
                                  <div style={{ display: "flex", paddingTop: "12px", paddingBottom: "12px", justifyContent: "space-between" }}>
                                    <div ref={dropdownContainerRef}>
                                      <div style={{ fontSize: "9px", fontWeight: "400", color: "#454545", paddingTop: "5px" }}>Select Members</div>
                                      <Dropdown show={dropdownOpen} drop="left" style={{ width: "200px" }}>
                                        <Dropdown.Toggle
                                          className="header_drop"
                                          style={{
                                            width: "150px",
                                            padding: "11px 10px",
                                            height: "34px",
                                            backgroundColor: "white",
                                            border: "1px solid #999",
                                          }}
                                          onClick={toggleDropdown}
                                        >
                                          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div style={{ fontSize: "10px", fontWeight: "500", color: "#454545" }}>{storeMem}</div>
                                            <div><img src={dropdownArrow} /></div>
                                          </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ height: "210px", overflow: "scroll" }}>
                                          <Dropdown.Item>
                                            <div style={{ border: "1px solid #EDEDED", display: "flex", width: "194px", borderRadius: "8px", height: "39px", padding: "6px 16px", justifyContent: "space-between" }}>
                                              <input
                                                placeholder="Search"
                                                className="searchinput"
                                                style={{ width: "140px" }}
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                              />
                                              <div><img src={MagnifyingBlack} alt="" /></div>
                                            </div>
                                          </Dropdown.Item>
                                          <Dropdown.Item onClick={() => { handleSelectAll(); setStoreMem("All") }}>
                                            <div style={{ display: "flex", justifyContent: "space-between" }} >
                                              <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>Select All</div>
                                              {isAllSelected && <div><img src={greenCheck} alt="" /></div>}
                                            </div>
                                          </Dropdown.Item>
                                          {filteredReportees.length === 0 ? (
                                            <Dropdown.Item>
                                              <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>Search Not Found</div>
                                            </Dropdown.Item>
                                          ) : (
                                            filteredReportees.map((data) => (
                                              <Dropdown.Item key={data._id} onClick={() => { handleSelect(data._id); setStoreMem(data?.fullName) }}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                  <div style={{ fontSize: "12px", fontWeight: "500", color: "#363535" }}>{data.fullName}</div>
                                                  {selectedIds.includes(data._id) && <div><img src={greenCheck} alt="" /></div>}
                                                </div>
                                              </Dropdown.Item>
                                            ))
                                          )}
                                          <Dropdown.Item onClick={handleClearSelection}>
                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                              <div className="clearest">Clear Selection</div>
                                            </div>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>


                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "12.5px",
                                          marginRight: "20px",
                                        }}
                                      >
                                        <span style={{ marginRight: "5px" }}>
                                          From :
                                        </span>
                                        <span>{getDateStr2(startDate)}</span>
                                        <span style={{ margin: "5px" }}>-</span>
                                        <span>{getDateStr2(endDate)}</span>
                                      </span>
                                      <Space wrap>
                                        <Select
                                          value={selectedPeriod}
                                          bordered={true}
                                          defaultValue="this year"
                                          style={{
                                            width: 120,
                                          }}
                                          onChange={handleChange}
                                          options={[
                                            {
                                              value: "this week",
                                              label: "This Week",
                                            },
                                            {
                                              value: "this month",
                                              label: "This month",
                                            },
                                            {
                                              value: "this quarter",
                                              label: "This Quarter",
                                            },
                                            {
                                              value: "this year",
                                              label: "This Year",
                                            },
                                            {
                                              value: "last week",
                                              label: "Last Week",
                                            },
                                            {
                                              value: "last month",
                                              label: "Last Month",
                                            },
                                            // {
                                            //   value: "last quarter",
                                            //   label: "Last Quarter",
                                            // },
                                            {
                                              value: "last year",
                                              label: "Last Year",
                                            },
                                          ]}
                                        />
                                      </Space>
                                    </div>
                                  </div>
                                )}
                                {headerTab === "Analytics" && (
                                  <MainLandingPageAnaly
                                    selectedPeriod={selectedPeriod}
                                    dates={dates}
                                    value={value}
                                    selectedIds={selectedIds}
                                    getDateStr2={getDateStr2}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                  />
                                )}
                              </div>
                              {/* {headerTab === "Leads Management" && (
                                <LeadManagement
                                  updatePanel={updatePanel}
                                  setUpdatePanel={setUpdatePanel}
                                  rerender={rerender}
                                  setRerender={setRerender}
                                />
                              )} */}
                              {headerTab === "Pipelines" && (
                                <Pipelines />
                              )}
                              {/* {headerTab === "Analytics" && (
                                <Analytic
                                  selectedPeriod={selectedPeriod}
                                  dates={dates}
                                  value={value}
                                  startDate={startDate}
                                  endDate={endDate}
                                  setStartDate={setStartDate}
                                  setEndDate={setEndDate}
                                />
                              )} */}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
              {showEmailModal && <LmsMail />}
              {/* <AddLeadModal setProgress={setProgress} /> */}
              <ModalRB
                aria-labelledby="contained-modal-title-vcenter"
                show={openShareEmail}
                size="lg"
                onHide={() => setOpenShareEmail(false)}
                centered>
                <ModalRB.Body>
                  <div>
                    <div className='d-flex align-items-center justify-content-between'>
                      <div className='share_head'>Send Email</div>
                      <div style={{ cursor: "pointer" }} onClick={() => { setMailFiles([]); setOpenShareEmail(false); }}><img src={crossBold} alt='' /></div>
                    </div>
                    <div style={{ gap: "17px", display: "flex", flexDirection: "column", marginTop: "1.2rem" }}>
                      <div>
                        <div>
                          <Dropdown drop="left">
                            <Dropdown.Toggle
                              className="toggleenew"
                              style={{
                                height: "auto",
                                width: "250px",
                                backgroundColor: "white",
                                border: "1px solid #CCCCCC",
                              }}
                            >
                              {/* Search Input inside Dropdown Toggle */}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: "100%",
                                  padding: "8px",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Search template"
                                  value={searchQuery}
                                  onChange={handleSearch}
                                  style={{
                                    flex: 1,
                                    border: "none",
                                    outline: "none",
                                    fontSize: "12px",
                                    color: "#454545",
                                  }}
                                />
                                <img
                                  src={downArrowGrey}
                                  alt="Toggle"
                                  style={{ marginLeft: "8px", cursor: "pointer" }}
                                />
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ width: "250px", height: "200px", overflowY: "scroll" }}>
                              {filteredTemplates.length > 0 ? (
                                filteredTemplates.map((item) => (
                                  <Dropdown.Item
                                    key={item.id}
                                    onClick={mailTemplateHandler.bind(null, item)}
                                  >
                                    {item.name}
                                  </Dropdown.Item>
                                ))
                              ) : (
                                <div style={{ padding: "8px", fontSize: "12px", color: "#999" }}>
                                  No results found
                                </div>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="mailModalToDiv">
                        <div style={{ fontSize: "12px", fontWeight: "400", color: "#333" }}>To:</div>
                        <div className='chips_whole'>
                          {allEmails?.length > 0 &&
                            allEmails
                              .filter((data) => data !== undefined && data !== null && data.trim() !== "")
                              .map((data) => (
                                <div className="singleChip" key={data}>
                                  <div className="chipName">{data}</div>
                                  <img
                                    src={close}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteEmail(data)}
                                  />
                                </div>
                              ))}

                          <input
                            type="text"
                            id="form4Example1"
                            placeholder="Enter mail"
                            onChange={(e) => setTempmail(e.target.value)}
                            onKeyUp={setMailData}
                            className="emailModalAddEmail"
                            style={{
                              height: "inherit",
                              fontSize: "13px",
                              border: "none"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ gap: "10px", display: "flex", paddingTop: "14px", paddingBottom: "14px", gap: "8px", alignItems: "center", borderBottom: "1px solid #DEDEDE" }}>
                      <div style={{ fontSize: "12px", fontWeight: "400", color: "#333" }}>Subject:</div>
                      <div><input className="input-custom"
                        type="text"
                        value={subjectForEmail}
                        onChange={(e) => setSubjectForEmail(e.target.value)} /></div>
                    </div>
                    <div style={{ paddingTop: "14px", position: "relative" }}>
                      {/* <textarea
                                className="main_email"
                                value={shareData}
                                onChange={(e) => setShareData(e.target.value)}
                            ></textarea> */}
                      {mentionDropdownVisible &&
                        dummyfilteredMentions.length > 0 && (
                          <div
                            className="mention-dropdown"
                            style={{
                              position: "absolute", background: "#fff", zIndex: 1000, padding: "8px",
                              borderRadius: "8px", height: "8rem", overflowY: "scroll",
                              boxShadow:
                                "0px -4px 8px 0px rgba(193, 192, 192, 0.08), 4px 8px 16px 0px rgba(193, 192, 192, 0.25)",
                              //  left: mentionPosition.left, top: mentionPosition.top,
                              right: "20%", top: "10%"
                            }}
                          >
                            {dummyfilteredMentions.map((mention) => (
                              <div
                                style={{ cursor: "pointer" }}
                                key={mention}
                                onClick={() => handleMentionSelect(mention)}
                              >
                                @{mention}
                              </div>
                            ))}
                          </div>
                        )}
                      <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        rows="10"
                        className="custom_quill"
                        value={shareData}

                        onChange={setShareData}
                        onKeyDown={handleKeyDownTask2}
                        style={{
                          width: "100%",
                          whiteSpace: "pre-wrap",
                          borderRadius: "4px",
                          display: "flex",
                          flexDirection: "column-reverse",
                        }
                        }
                        modules={{ toolbar: toolbarOptions }}
                      />
                    </div>
                    <div
                      className="chipContainer"

                      style={{ display: "flex", flexWrap: "wrap", gap: "6px", padding: "0px 20px", margin: "", height: mailFiles?.length > 1 ? "4.2rem" : "auto", marginTop: "10px" }}
                    >
                      {mailFiles?.filter((data) => data?.filename != undefined)?.map((file, index) => {
                        return (
                          <div className="chips">
                            <span style={{ whiteSpace: "nowrap" }}
                              onClick={() => { window.open(file?.path) }}>
                              {file?.filename}
                            </span>
                            <span>
                              <RxCross2
                                size={16}
                                style={{ cursor: "pointer" }}
                                color="#74737F"
                                onClick={() => removeFile(index)}
                              />
                            </span>
                          </div>
                        );
                      })}


                      {mailFiles?.filter((file, inde) => {
                        return
                      })}
                    </div>
                    <div className="attach-main" style={{ display: "flex", marginLeft: "10px", height: "36px", width: "116px", padding: "4px 8px", alignItems: "center", borderRadius: "1px", gap: "4px", background: "#fff", justifyContent: "center" }}>
                      <img
                        src={attachedFile}
                        className="attachfile"
                        alt=""
                        style={{ borderWidth: 0 }}
                        onClick={() => uploadFileRef.current.click()}
                      />
                      <span
                        className="attach"
                        style={commonCss}
                        onClick={() => uploadFileRef.current.click()}
                      >
                        Attach Files
                      </span>
                      {mailFiles?.length > 0 && <span>({mailFiles?.filter((data) => data?.filename != undefined)?.length})</span>}
                    </div>
                    <div className='whole'> <div className='send_btn' onClick={() => {
                      for (let i = 0; i < allEmails?.length; i++) {
                        sendEmail(allEmails[i]);
                      }
                      setOpenShareEmail(false);
                    }}>Send</div></div>
                  </div>
                </ModalRB.Body>
              </ModalRB>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lms;
